.footer {
    text-align: center;
    margin-top: auto;

    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    bottom: 1%;

    font-weight: 500;
    letter-spacing: -0.04em;
    color: #000000;
}

a {
    text-decoration: none;
    color: rgb(85, 85, 85);
    cursor: pointer;
}

a:hover {
    color: #7B61FF;
}