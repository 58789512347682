.title {
    margin-top: 20px;
    height: 50px;
}

.title-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 431px));
    grid-gap: 20px;
    justify-content: center;
}

.title-first-grid {
    margin-top: 20px;
    justify-content: center;
    display: grid;
    grid-template-columns: minmax(340px, 430px);
}

.title-first-text {
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.04em;
    color: #000000;
}

.path-group {
    display: flex;
}

.path {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-left: 15px;

    cursor: default;
    font-family: Segoe UI, Roboto, Roboto;
    font-style: 600;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.04em;
    color: #000000;
}

.path-action {
    cursor: pointer;
    &:active, &:hover {
        opacity: 0.7;
    }
}

.path>div {
    display: flex;
    margin-right: 2pt;
}


.chevron-left {
    margin-top: 12px;
    cursor: pointer;
}

.path-strong {
    font-weight: 700;
}