.select {
    margin: 0 auto;
    position: relative
}

.select.is-active .select__body {
    display: block;
}

.select__body {
    max-height: 190px;
    display: none;
    z-index: 1;
    position: absolute;
    width: 100%;
    padding-top: 5px;
    background-color: white;
    border: 0.5px solid #c1c1c173;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
    overflow-y: scroll;
}

.select__body::-webkit-scrollbar {
    width: 0;
}

.select__item {
    cursor: pointer;
    line-height: 20px;
    padding: 4px;
    padding-left: 15px;
}

.select__item:hover {
    background-color: #f2f2f2;
}

.select_item-text {
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    resize: none;
}

.select_item-text_bic {
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    resize: none;
}

@media only all and (max-width: 880px) {

    .select_item-text {
        font-size: 15px;
    }

    .select_item-text_bic {
        font-size: 14px;
    }
}