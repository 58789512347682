.checkbox-square {
    cursor: pointer;
}

.checkbox-text {
    position: relative;
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.04em;
    color: #000000;

    margin-left: 10px;
}

.checkbox-unit {
    display: grid;
    grid-template-columns: auto auto;
    margin-left: 3px;
}

.checkbox-group {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 30px;
    justify-content: center;
}

.checkbox-text-small {
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: -0.04em;
    color: #B0B0B0;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;

    margin-left: 2px;
    margin-top: 2px;
}

@media only all and (max-width: 880px) {

    .checkbox-text {
        font-size: 14px;
    }

    .checkbox-text-small {
        font-size: 12px;
    }
}