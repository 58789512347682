.input-group {
    display: grid;
    grid-gap: 5px;
    margin-top: 13px;
    margin-bottom: 30px;
    position: relative;
}

.input-group-name {
    display: grid;
    grid-gap: 5px;
}

.input-valid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 351px));
    justify-content: center;
}

.valid-blue {
    background: rgba(200, 175, 255, 0.2);
    margin-top: 3px;
    /* margin-bottom: 10px; */
}

.input {
    padding: 6px 15px;
    width: 100%;
    border: 0.5px solid #c1c1c173;
    box-sizing: border-box;
}

input::placeholder, textarea::placeholder {
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.04em;
    color: #C1C1C1;
}

input[type="text"]:disabled {
    cursor:not-allowed;
}

textarea[type="text"]:disabled {
    cursor:not-allowed;
}

input::-webkit-calendar-picker-indicator {
    display: none;
    opacity: 0;
}

input[type="text"], textarea[type="text"] {
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    resize: none;
}

textarea[type="text"] {
    overflow: hidden;
}

input[type="text"]:focus, textarea[type="text"]:focus {
    outline-color: #7B61FF;
}

.input-title {
    padding: 3px 10px 5px 17px;
    /* min-height: 27px; */
}

.input-title-text {
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    /* align-items: center; */
    letter-spacing: -0.04em;
    color: #000000;
}

.input-title-star {
    color: red;
    padding-left: 1px;
}

.input-valid-text {
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: -0.04em;

    /* margin-left: 2px; */
}

.input-valid-inn {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
}

.input-valid-inn:hover {
    opacity: 0.7;
}

.input-comment {
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: -0.04em;
    color: #7B61FF;
    margin-top: 5px;
    margin-bottom: 7px;
    margin-left: 17px;
}

.input-comment-text {
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: -0.04em;
    color: #000000;
    
}

.input-comment-only{
    display: grid;
    margin-top: 5px;
    margin-bottom: 6px;
}

.input-comment-only-title {
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: -0.04em;
    color: #7B61FF;
}

.input-frame-auto{
    display: flex;
    position: relative;
    width: 351px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: auto;
    flex-wrap: wrap;
    padding-bottom: 5pt;
    padding-top: 5pt;
    padding-left: 12pt;
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.04em;
    color: #C1C1C1;
    padding-top: 6.5pt;
}

@media only all and (max-width: 880px) {

    input::placeholder, textarea::placeholder {
        font-size: 14px;
    }

    input[type="text"], textarea[type="text"] {
        font-size: 14px;
    }

    .input-title-text {
        font-size: 14px;
    }

    .input-valid-text {
        font-size: 15px;
    }

    .input-comment {
        font-size: 15px;
    }
    
    .input-comment-text {
        font-size: 15px;
    }

    .input-comment-only-title {
        font-size: 15px;
    }
    
    .input-frame-auto{
        font-size: 14px;
    }
  }