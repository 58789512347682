.header {
    position: fixed;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 430px));
    grid-gap: 20px;
    justify-content: center;
    height: 70px;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(14px);
    z-index: 1;
    align-items: center;
}

.header-final{
    position: fixed;
    width: 100%;
    display: grid;
    justify-content: center;
    height: 70px;
    z-index: 1;
    align-items: center;
}

.header-logo {
    display: grid;
    padding-left: 35px;
    grid-template-columns: 61px 1px 70px;
    grid-gap: 5px;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}

.logo-line {
    height: 25px;
    border-left: 0.5px solid rgba(0, 0, 0, 0.41);
}

.logo-text {
    position: relative;
    top: 2px;
    font-family: Segoe UI, Roboto, Open Sans;
    font-weight: 600;
    font-size: 14px;
}

.header-button {
    width: 154px;
    height: 33px;
    background: #7B61FF;
    border-radius: 10px;
    border: none;
    outline: none;
    margin-left: 273px;
    display: grid;
    align-items: center;
    justify-items: center;
    cursor: pointer;

    animation: show 1s;

    &:focus,
    &:hover {
        background: #5533FF;
    }

    &:active {
        background: #5533FF;
        opacity: .9;
    }
    &:disabled {
        background: #C1C1C1;
        cursor: not-allowed;
    }
}


.header-button-text{
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}

@keyframes show {

    from {
        opacity: 0.1;
    }

    to {
        opacity: 1;
    }
}

@media only all and (max-width: 879px) {

    .header {
        display: flex;
        grid-gap: 65px;
    }

    .header-logo {
        padding-left: 0;
    }
    
    .header-button {
        margin-left: 0;
    }

    .logo-text,
    .header-button-text{
        font-size: 16px;
    }
}