.prew-group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 350px));
    /* grid-gap: 20px; */
    justify-content: center;
    /* margin-top: 25px; */
}

.prew {
    margin-top: 20px;
}

.prew-header {
    display: grid;
    grid-template-columns: 45px 1fr;
}

.prew-line {
    border-top: 0.5px solid #C1C1C1;
    transform: rotate(-0.22deg);
    margin-top: 3px;
}

.prew-name {
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.04em;
    color: #7B61FF;
    cursor: pointer;
    margin-top: 5px;
}

.prew-delete {
    display: grid;
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: -0.04em;
    color: #7B61FF;
    cursor: pointer;
    margin-top: 8px;
    justify-content: end;
}

.prew-header-text {
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
}

.prew-check-circle {
    margin-top: 3px;
}

.prew-group-btn {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    margin-top: 10px;
}

.prew-button {
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: -0.04em;
    color: #7B61FF;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}

.disabled {
    opacity: 0.3;
    cursor: not-allowed;
}

.prew-info {
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.04em;
}

.prew-result {
    /* grid-template-rows: repeat(auto-fit, minmax(260px, 350px)); */
    grid-template-rows: 600px 600px;
}

.result-line {
    border-top: 0.5px solid #C1C1C1;
    transform: rotate(-0.22deg);
    margin-top: 17px;
    margin-right: 20px;
    margin-left: 20px;
}

@media only all and (max-width: 879px) {
    
    .prew-name {
        font-size: 14px;
    }
    
    .prew-delete {
        font-size: 13px;
    }
    
    .prew-header-text {
        font-size: 16px;
    }
    
    .prew-button {
        font-size: 13px;
    }
    
    .prew-info {
        font-size: 14px;
    }
    
}