.modal-wrapper {
    position: fixed;
    background: rgba(0, 0, 0, .2);
    backdrop-filter: blur(2px);
    grid-template-columns: repeat(auto-fit, minmax(340px, 430px));
    justify-content: center;
    align-items: start;
    padding-top: 92px;
    left: 0;
    top: 0;
    z-index: 1000000;

}

.modal-wrapper.open {
    height: 100%;
    width: 100%;
    display: grid;

    overflow: scroll;
    -ms-overflow-style: none;
    /* scrollbar-width: none; */
}

.modal-wrapper.close {
    height: 0;
    display: none;
}

.modal-button-group{
    display: grid;
    grid-template-columns: repeat(2, 167.5px);
    grid-template-rows: 33px;
    justify-content: center;
    grid-gap: 16px;
    margin-top: 20px;
}

.modal-button-send {
    background: linear-gradient(302.3deg, #F4F4F6 30.63%, #F4F7FF 99.53%);

    border-radius: 10px;
    border-width: 0px;
}

.modal-button-send:focus {
    background: linear-gradient(302.3deg, #E9E9EA 30.63%, #F4F7FF 99.53%);
}

.modal-button-send:hover {
    background: linear-gradient(302.3deg, #E9E9EA 30.63%, #F4F7FF 99.53%);
}

.modal-button-send:active {
    background: linear-gradient(302.3deg, #F4F7FF 99.53%, #E9E9EA 30.63%);
}

.modal-button-send:disabled {
    background: linear-gradient(302.3deg, #F4F4F6 30.63%, #F4F7FF 99.53%);
}

.modal-button-cancel {
    border-radius: 10px;
    border-width: 0px;
    border: 1px solid #C1C1C1;
    border-radius: 10px;
}

.modal-button-text {
    font-family: 'Segoe UI', Roboto, Open Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.04em;
    color: #000000;
}

.modal-button-text-disabled {
    font-family: 'Segoe UI', Roboto, Open Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.04em;
    color: rgba(176, 176, 176, 1);
}

@media only all and (max-width: 879px) {

    .modal-button-text {
        font-size: 14px;
    }
    
    .modal-button-text-disabled {
        font-size: 14px;
    }
}