.final-frame {
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

.final-image {
    position: relative;
}

.final-text-1 {
    /* Благодарим за сотрудничество! */
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */
    text-align: center;
    letter-spacing: -0.04em;
    color: #7B61FF;
}

.final-text-2 {
    /* Мы уже получили ваши данные, скоро все проверим и сообщим результат.  */
    padding-top: 1%;
    margin-right: 10%;
    margin-left: 10%;
    /* position: relative; */
    /* Header2 14 S */
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #000000;
}

.final-text-3 {
    /* Европейская медиагруппа © 2021 | emg.fm | Техподдержка support@emg.fm */
    position: absolute;
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    bottom: 1%;
    /* identical to box height */
    text-align: center;
    letter-spacing: -0.04em;
    color: #000000;
}

@media only all and (max-width: 880px) {
    
    .final-text-1 {
        font-size: 20px;
    }

    .final-text-2 {
        font-size: 16px;
    }
    
    .final-text-3 {
        font-size: 14px;
    }
}