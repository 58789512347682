.button-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 350px));
  grid-gap: 25px;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 20px;
  width: auto;
}

.button-result {
  display: grid;
  justify-content: center;
  height: 33px;
  background: #7B61FF;
  border-radius: 10px;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button-result:focus {
  background: #5533FF;
}

.button-result:hover {
  background: #5533FF;
}

.button-result:active {
  background: #5533FF;
  opacity: .9;
}

.button-result-text {
  height: 21px;
  font-family: Segoe UI, Roboto, Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.04em;
  color: rgba(233, 233, 234, 1);
}

.button-input {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 350px));
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 10px;
  width: auto;
}