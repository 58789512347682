.form {
    display: grid;
    padding-top: 50px;
}

.frame-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 430px));
    grid-gap: 20px;
    justify-content: center;
    margin-bottom: 30px;
    justify-items: center;
}

.frame {
    display: grid;
    grid-template-rows: 50px 0.5px 1fr;

    box-shadow: 0 0 24px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 20px;
    padding-bottom: 5px;
    flex-direction: column;
    align-items: start;
}

.frame-fix {
    display: grid;
    grid-template-rows: auto auto 0.5px 1fr;

    box-shadow: 0 0 24px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 20px;
    padding-bottom: 5px;
    flex-direction: column;
    align-items: center;
}

.frame-group {
    display: grid;
    grid-gap: 20px;
}

.frame-header {
    display: grid;
    /* grid-template-columns: 74px 1fr; */
    grid-template-columns: minmax(60px, 74px) minmax(340px, 356px);
    margin-top: 15px;
}

.frame-header-icon {
    display: grid;
    /* justify-items: center; */
    align-items: center;
    justify-items: end;
    padding-right: 15px;
    /* margin-left: 23px; */
}

.frame-header-name {

    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -.04em;
    color: #000;

}

.frame-poptext {
    display: grid;
    grid-template-columns: minmax(60px, 74px) minmax(340px, 356px);
}

.frame-poptext-text {
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.04em;
    color: Darkgray;
    padding-bottom: 10px;
}

.frame-result-poptext-text {
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.04em;
    color: #7B61FF;
    padding-bottom: 10px;
}

.frame-poptext-path {
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.04em;
    color: #7B61FF;
    margin-bottom: 10px;
}

.frame-line {
    width: 100%;
    border-top: 0.5px solid #c1c1c1;
}

/* animation */
.frame {
    animation: show .4s;
}

.frame-fix {
    animation: show .4s;
}

.title-first-text {
    animation: show 1s;
}

.path>div {
    animation: show 1s;
}

@keyframes show {

    from {
        opacity: .1;
    }

    to {
        opacity: 1;
    }
}

@media only all and (max-width: 880px) {

    .title-first-text {
        font-size: 14px;
        margin-left: 5px;
    }

    .path {
        font-size: 16px;
    }

    .frame-header-name {
        font-size: 20px;
    }

    .frame-poptext-text {
        font-size: 14px;
    }
    
    .frame-result-poptext-text {
        font-size: 14px;
    }
    
    .frame-poptext-path {
        font-size: 14px;
    }
}

.frame-grid-center {
    font-family: Segoe UI, Roboto, Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -.04em;
    color: #000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 500px;
    text-indent: 20px;
}

.select_item__form {
    margin: 0 auto;
    position: absolute;
    max-height: 217px;
    display: grid;
    width:350px;
    padding-top: 5px;
    background-color: white;
    border: 0.5px solid #c1c1c173;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
    overflow-y: scroll;
    top: 18%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
}