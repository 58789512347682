button {
  background: none;
  border: none;
}

.button {
  height: 33px;
  background: linear-gradient(302.3deg, #f4f4f6 30.63%, #f4f7ff 99.53%);
  border-radius: 10px;
  border-width: 0px;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    background: linear-gradient(302.3deg, #E9E9EA 30.63%, #F4F7FF 99.53%);
  }

  &:active,
  &:disabled {
    background: linear-gradient(302.3deg, #F4F7FF 99.53%, #E9E9EA 30.63%);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

}

.large-button {
  height: 43px;
  width: 100%;
}

.button-text {
  font-family: 'Segoe UI', Roboto, Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.04em;
  justify-content: space-around;
  color: #000000;
}

.button-text-disabled {
  font-family: 'Segoe UI', Roboto, Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.04em;
  justify-content: space-around;
  color: #B0B0B0;

  padding-right: 5pt;
  padding-left: 5pt;
}

@media only all and (max-width: 880px) {

  .button-text {
    font-size: 14px;
  }

  .button-text-disabled {
    font-size: 14px;
  }
}