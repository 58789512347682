body {
    /* background: linear-gradient(302.3deg, #f4f4f6 30.63%, #f4f7ff 99.53%); */
    background: linear-gradient(302.3deg, #f4f4f6 30.63%, #f4f7ff 99.53%);
    margin: 0px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

::-webkit-scrollbar {
/* chrome based */
    width: 0px;
    /* ширина scrollbar'a */
    background: transparent;
    /* опционально */
}

.App {
    display: grid;
    grid-template-rows: max-content 1fr 10px;
    min-height: 100vh;
}